<template>
  <s-dialog
    v-model:visible="showDialog"
    :append-to-body="true"
    :show-close="true"
    type="W720"
    class="custom-content-dialog"
    @open="onOpen"
    @close="onClosed"
  >
    <template
      v-if="!pageLoading"
      #title
    >
      {{ language.SHEIN_KEY_PC_27387 }}
    </template>

    <div
      v-if="pageLoading"
      class="custom-content-dialog__loading"
    >
      <div class="la-ball-pulse la-ball-pulse-2x">
        <div></div>
        <div></div>
        <div></div>
      </div>
    </div>

    <div
      v-if="!pageLoading"
      class="custom-content-dialog__body"
    >
      <div class="custom-content-tips">
        <sui_icon_caution_18px_1
          class="custom-content-tips__icon"
          size="14px"
        />
        <span
          class="custom-content-tips__text"
          v-html="language.SHEIN_KEY_PC_35115"
        >
        </span>
      </div>
      <div
        v-if="customizationInfo.effects.length"
        class="custom-content-module"
      >
        <h6 class="custom-content-module__title">
          {{ language.SHEIN_KEY_PC_27384 }}
        </h6>
        <div class="image-preview-list">
          <img
            v-for="(item, index) in customizationInfo.effects"
            :key="index"
            v-tap="{
              id: '2-33-3',
            }"
            :data-src="transformImg({ img: dprInsert({ img: item }) })"
            class="image-preview-item lazyload"
            @click="onPreviewImage(index)"
          />
        </div>
      </div>

      <div
        v-if="customizationInfo.texts.length"
        class="custom-content-module"
      >
        <h6 class="custom-content-module__title">
          {{ language.SHEIN_KEY_PC_27389 }}
        </h6>
        <div class="copywriting-detail-list">
          <span
            v-for="(item, index) in customizationInfo.texts"
            :key="index"
            class="copywriting-detail-item"
          >
            {{ item }}
          </span>
        </div>
      </div>

      <div
        v-if="customizationInfo.images.length"
        class="custom-content-module"
      >
        <h6 class="custom-content-module__title">
          {{ language.SHEIN_KEY_PC_27385 }}
        </h6>
        <div class="material-detail-list">
          <img
            v-for="(item, index) in customizationInfo.images"
            :key="index"
            v-tap="{
              id: '2-33-3',
            }"
            class="material-detail-item lazyload"
            :data-src="transformImg({ img: dprInsert({ img: item }) })"
            @click="onPreviewImage(index + customizationInfo.effects.length)"
          />
        </div>
      </div>
    </div>

    <template
      v-if="!pageLoading"
      #footer
    >
      <s-button
        :type="['primary', 'H44PX']"
        @click="showDialog = false"
      >
        {{ language.SHEIN_KEY_PC_27386 }}
      </s-button>
    </template>
  </s-dialog>

  <image-view-dialog
    v-if="!pageLoading"
    v-model:visible="showPreviewDialog"
    :language="language"
    :active-index="activeIndex"
    :urls="previewUrls"
  />
</template>

<script>
import { uniqueId } from 'lodash'
import { daEventCenter } from 'public/src/services/eventCenter'
import { tap, expose } from 'public/src/pages/common/analysis/directive.js'
import dprInsertMixin from './dprInsertMixins.js'
import ImageViewDialog from './ImageViewDialog.vue'
import { transformImg } from '@shein/common-function'
import { sui_icon_caution_18px_1 } from '@shein-aidc/icon-vue3'

daEventCenter.addSubscriber({ modulecode: '2-33' })

export default {
  name: 'CustomContentDialog',
  components: {
    ImageViewDialog,
    sui_icon_caution_18px_1
  },
  directives: {
    expose,
    tap,
  },
  mixins: [dprInsertMixin],
  data() {
    return {
      activeIndex: 0,
      showDialog: false,
      showPreviewDialog: false,
      language: {},
      pageLoading: true,
      options: {},
    }
  },
  computed: {
    customizationInfo() {
      const preview = this.options?.data?.preview || {}
      const info = {
        effects: [],
        texts: [],
        images: [],
      }
      if (Array.isArray(preview?.effects)) {
        info.effects = preview.effects
      }
      if (Array.isArray(preview?.texts)) {
        info.texts = preview.texts
      }
      if (Array.isArray(preview?.images)) {
        info.images = preview.images
      }
      return info
    },
    previewUrls() {
      const { effects = [], images = [] } = this.customizationInfo
      const newEffects = effects.map(url => ({ url, type: 1, id: uniqueId('effect') }))
      const newImages = images.map(url => ({ url, type: 2, id: uniqueId('image') }))
      return [...newEffects, ...newImages]
    },
  },
  mounted() {
    this.getPageLanguage()
  },
  methods: {
    transformImg,
    getAnalysisData() {
      const { effects = [], texts = [], images = [] } = this.customizationInfo
      const params = {
        preview: effects.length || 0,
        texts: texts.length || 0,
        images: images.length || 0,
      }
      if (this.options?.analysisData) {
        Object.assign(params, this.options.analysisData)
      }
      return params
    },
    triggerCallback(callbackName, data) {
      if (typeof this.options[callbackName] === 'function') {
        this.options[callbackName](data)
      }
    },
    async getPageLanguage() {
      this.pageLoading = true
      // eslint-disable-next-line no-undef
      const res = await schttp({
        url: '/api/common/language/get',
        params: { page: 'custom-content' },
      })
      this.language = res?.language || {}
      this.pageLoading = false
    },
    onPreviewImage(index) {
      this.activeIndex = index
      this.showPreviewDialog = true
      this.triggerCallback('preview', index)
    },
    open(options) {
      this.options = options
      this.showDialog = true
    },
    close() {
      this.showDialog = false
    },
    onOpen() {
      daEventCenter.triggerNotice({
        daId: '2-33-1',
        extraData: this.getAnalysisData(),
      })
      this.triggerCallback('open')
    },
    onClosed() {
      daEventCenter.triggerNotice({
        daId: '2-33-2',
      })
      this.triggerCallback('close')
    },
  },
}
</script>

<style lang="less">
.custom-content-dialog {
  &__body {
    padding: 12px;
    border: 1px solid @sui_color_gray_weak1;
  }

  &__loading {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100px;
  }

  .custom-content-tips{
    color: #959595;
    font-size: 12px;
    margin-bottom: 16px;
    position: relative;
    &__icon{
      position: absolute;
      .left(0);
      top: 2px;
    }
    &__text{
      display: -webkit-box;
      -webkit-line-clamp: 3;
      -webkit-box-orient: vertical;
      overflow: hidden;
      text-overflow: ellipsis;
      text-indent: 16px;
    }
  }

  .image-preview-list {
    display: flex;
    flex-wrap: wrap;
    gap: 8px 4px;
  }

  .image-preview-item {
    width: 61px;
    height: 61px;
    object-fit: cover;
    cursor: pointer;
  }

  .copywriting-detail-list {
    display: flex;
    flex-wrap: wrap;
    gap: 8px 8px;
  }

  .copywriting-detail-item {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    padding: 6px 12px;
    color: @sui_color_gray_dark2;
    font-family: 'SF UI Text';
    font-size: 12px;
    background: @sui_color_gray_weak2;
  }

  .material-detail-list {
    display: flex;
    flex-wrap: wrap;
    gap: 8px 10px;
  }

  .material-detail-item {
    width: 67px;
    height: 67px;
    object-fit: cover;
    cursor: pointer;
  }
}

.custom-content-module {
  & + & {
    margin-top: 24px;
  }

  &__title {
    color: @sui_color_gray_dark3;
    font-family: 'SF UI Text';
    text-transform: capitalize;
    word-break: break-all;
    margin-bottom: 8px;
  }
}
</style>
